
export class AxesRow {
  Axis?: string;
  Category?: string;
  Values?: string[];
}
export class LevelsRow {
  Name?: string;
  Level?: number;
  Colour?: string;
  Description?: string;
  skytrust_ref_LevelID?: number;
}

export class MatrixRow {
  Risk?: RiskMatRow;
  XIndex?: number;
  YIndex?: number;
}

export class RiskMatRow {
  Level?: number;
  Score?: number;
}

export class RiskMatrixJsonRowNew {
  Axes?: AxesRow[];
  Levels?: LevelsRow[];
  Matrix?: MatrixRow[];
}

// export class IRiskMatrixDocumentNew {
//   Axes: {
//     Axis: string;
//     Values: string[];
//     Category: string;
//   }[];
//   Levels: {
//     Name: string;
//     Level: number;
//     Colour: string;
//     Description: string;
//     skytrust_ref_LevelID: number;
//   }[];
//   Matrix: {
//     Risk: {
//       Level: number;
//       Score: number;
//     };
//     XIndex: number;
//     YIndex: number;
//   }[];
// };

export const riskMatrixDocumentNew = {
  "Axes": [
    {
      "Axis": "Y",
      "Values": [
        "Almost Certain",
        "Likely",
        "Possible",
        "Unlikely",
        "Very Unlikely",
        "abc"
      ],
      "Category": "Likelihood"
    },
    {
      "Axis": "X",
      "Values": [
        "Negligible Injury - First aid treatment",
        "Minor Injury - Injury requiring medical treatment",
        "Moderate Injury - Injury requiring extensive medical treatment",
        "Major Injury - Injury resulting in permanent incapacitation",
        "Catastrophic Injury - Injuries resulting in single or multiple deaths"
      ],
      "Category": "WHS"
    },
    {
      "Axis": "X",
      "Values": [
        "Negligible or no quality damage/impact",
        "Minor quality damage/impact",
        "Significant quality damage/impact",
        "Major quality damage/impact",
        "Extensive quality damage & loss"
      ],
      "Category": "Quality"
    },
    {
      "Axis": "X",
      "Values": [
        "Negligible or no environmental damage/impact",
        "Significant environment damage/impact",
        "Major environmental damage/impact",
        "Extensive environmental damage & biodiversity degradation",
        "Minor environmental damage/impact"
      ],
      "Category": "Environment"
    },
    {
      "Axis": "X",
      "Values": [
        "Negligible financial loss < = $5k",
        "Notable financial loss $5k - $50k",
        "Substantial financial loss $50k - $500k",
        "Significant financial loss $1m+",
        "Extensive financial loss $5m+"
      ],
      "Category": "Business"
    },
    {
      "Axis": "X",
      "Values": [
        "*****INSIGNIFICANT 1",
        "MINOR 2",
        "MODERATE 3",
        "MAJOR 4",
        "CATASTROPHIC 5"
      ],
      "Category": "Click to edit"
    }
  ],
  "Levels": [
    {
      "Name": "BIG Low",
      "Level": 0,
      "Colour": "079dd9",
      "Description": "BIG Low risk",
      "skytrust_ref_LevelID": 229410
    },
    {
      "Name": "SO Moderate",
      "Level": 1,
      "Colour": "f23fe6",
      "Description": "SO Moderate risk",
      "skytrust_ref_LevelID": 229411
    },
    {
      "Name": "High",
      "Level": 2,
      "Colour": "faf337",
      "Description": "High risk",
      "skytrust_ref_LevelID": 229412
    },
    {
      "Name": "Extreme",
      "Level": 3,
      "Colour": "ee3f3f",
      "Description": "Extreme risk",
      "skytrust_ref_LevelID": 229413
    }
  ],
  "Matrix": [
    {
      "Risk": {
        "Level": 0,
        "Score": 1
      },
      "XIndex": 0,
      "YIndex": 4
    },
    {
      "Risk": {
        "Level": null,
        "Score": 2
      },
      "XIndex": 0,
      "YIndex": 5
    },
    {
      "Risk": {
        "Level": 0,
        "Score": 3
      },
      "XIndex": 1,
      "YIndex": 4
    },
    {
      "Risk": {
        "Level": null,
        "Score": 4
      },
      "XIndex": 4,
      "YIndex": 5
    },
    {
      "Risk": {
        "Level": 0,
        "Score": 5
      },
      "XIndex": 1,
      "YIndex": 3
    },
    {
      "Risk": {
        "Level": 0,
        "Score": 6
      },
      "XIndex": 2,
      "YIndex": 4
    },
    {
      "Risk": {
        "Level": 0,
        "Score": 7
      },
      "XIndex": 3,
      "YIndex": 4
    },
    {
      "Risk": {
        "Level": 1,
        "Score": 8
      },
      "XIndex": 1,
      "YIndex": 2
    },
    {
      "Risk": {
        "Level": 1,
        "Score": 9
      },
      "XIndex": 2,
      "YIndex": 3
    },
    {
      "Risk": {
        "Level": 0,
        "Score": 10
      },
      "XIndex": 0,
      "YIndex": 1
    },
    {
      "Risk": {
        "Level": 1,
        "Score": 11
      },
      "XIndex": 0,
      "YIndex": 0
    },
    {
      "Risk": {
        "Level": 2,
        "Score": 12
      },
      "XIndex": 1,
      "YIndex": 0
    },
    {
      "Risk": {
        "Level": 2,
        "Score": 13
      },
      "XIndex": 2,
      "YIndex": 2
    },
    {
      "Risk": {
        "Level": 1,
        "Score": 14
      },
      "XIndex": 3,
      "YIndex": 3
    },
    {
      "Risk": {
        "Level": 1,
        "Score": 15
      },
      "XIndex": 4,
      "YIndex": 4
    },
    {
      "Risk": {
        "Level": 1,
        "Score": 16
      },
      "XIndex": 1,
      "YIndex": 1
    },
    {
      "Risk": {
        "Level": 2,
        "Score": 17
      },
      "XIndex": 2,
      "YIndex": 1
    },
    {
      "Risk": {
        "Level": 2,
        "Score": 18
      },
      "XIndex": 3,
      "YIndex": 2
    },
    {
      "Risk": {
        "Level": 2,
        "Score": 19
      },
      "XIndex": 4,
      "YIndex": 3
    },
    {
      "Risk": {
        "Level": 2,
        "Score": 20
      },
      "XIndex": 2,
      "YIndex": 0
    },
    {
      "Risk": {
        "Level": 3,
        "Score": 21
      },
      "XIndex": 3,
      "YIndex": 1
    },
    {
      "Risk": {
        "Level": 2,
        "Score": 22
      },
      "XIndex": 4,
      "YIndex": 2
    },
    {
      "Risk": {
        "Level": 3,
        "Score": 23
      },
      "XIndex": 3,
      "YIndex": 0
    },
    {
      "Risk": {
        "Level": 0,
        "Score": 24
      },
      "XIndex": 0,
      "YIndex": 3
    },
    {
      "Risk": {
        "Level": 3,
        "Score": 25
      },
      "XIndex": 4,
      "YIndex": 0
    },
    {
      "Risk": {
        "Level": 3,
        "Score": 26
      },
      "XIndex": 4,
      "YIndex": 1
    },
    {
      "Risk": {
        "Level": null,
        "Score": 27
      },
      "XIndex": 1,
      "YIndex": 5
    },
    {
      "Risk": {
        "Level": null,
        "Score": 28
      },
      "XIndex": 2,
      "YIndex": 5
    },
    {
      "Risk": {
        "Level": null,
        "Score": 29
      },
      "XIndex": 3,
      "YIndex": 5
    },
    {
      "Risk": {
        "Level": 0,
        "Score": 30
      },
      "XIndex": 0,
      "YIndex": 2
    }
  ]
};