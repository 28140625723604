import { Decorator } from "final-form";
import { RuleProperties } from "json-rules-engine";
import React, { ComponentType, ElementType, ReactNode } from "react";
import { FormRenderProps } from "react-final-form";
import { Guid, HideElement, IsNotNullOrWhiteSpace, IsNullOrWhiteSpace, ShowElement, convertUTCDateToLocalTimezone, isNotEmptyArray, isNotNullAndUndefined } from "../../RAFComponents/helpers/utils";
import { RAFDataType } from '../../RAFComponents/models/Common/RAFDataType';
import { RAFEntityBase } from "../../RAFComponents/models/Common/RAFEntityBase";
import { ILookupGroupRow, ILookupRow } from "../../RAFComponents/models/CompositeTypes/ILookupRow";
import { RAFBusinessAction, RAFLayout } from "../../constants/Common/Constants";
import { RAFCustomFilter, RAFCustomOperator } from "../RAFViewPanels/RAFFilterColumn/RAFCustomFilter";

import { DataManager, Query } from '@syncfusion/ej2-data';
import { QueryBuilder, RuleModel } from "@syncfusion/ej2-react-querybuilder";
import { RichTextEditorComponent } from "@syncfusion/ej2-react-richtexteditor";
import moment from "moment";
import { getSFRulesByRAFRRule } from "../../RAFMaster/RMModules/FormLibrary/FormLibaryHelper";
import { RAFRuleAction } from "../models/Common/FormLibraryRow";
import { ValueJson } from "../models/Common/QueryAttributeJM";
import { LookUpRow } from "../models/CompositeTypes/LookUpRow";

//export const isRequired = value => (value ? undefined : 'Required');
export const isRequired = value => {
    if (isNotNullAndUndefined(value)) {
        if (typeof (value) === 'string') {
            return (IsNullOrWhiteSpace(value.trim()) ? 'Required' : undefined);
        }
        // else if (typeof (value) === 'object') {
        //     return (isEmptyArray(value) ? 'Required' : undefined);
        // }
        else {
            return undefined;
        }
    } else {
        return 'Required';
    }
};

export const isRequiredForArray = value => {
    if (isNotEmptyArray(value)) {
        return undefined;
    } else {
        return 'Required';
    }
};

export const isEmail = value => {
    let hasError = false, error = "";
    if (value && value.trim() === "") {
        hasError = true;
        error = "Email cannot be empty";
    } else if (
        !/^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/.test(
            value
        ) && !IsNullOrWhiteSpace(value)
    ) {
        hasError = true;
        error = "Please provide valid email";
    } else {
        hasError = false;
        error = "";
    }
    return (hasError ? error : undefined);
};

export const createRAFHTMLValidator = (htmlEditorId, required) => (value) => {
    //console.log('Validating field:', htmlEditorId);
    // Add your validation logic here
    if (required) {
        if (IsNullOrWhiteSpace(value)) {
            const richTextEditorElement = document.querySelector('#' + htmlEditorId);
            //console.log('richTextEditorElement:', richTextEditorElement, richTextEditorElement["ej2_instances"]);
            if (
                isNotNullAndUndefined(richTextEditorElement) &&
                isNotNullAndUndefined(richTextEditorElement["ej2_instances"]) &&
                isNotNullAndUndefined(richTextEditorElement["ej2_instances"][0]) &&
                isNotNullAndUndefined(richTextEditorElement["ej2_instances"][0] as RichTextEditorComponent)
            ) {
                const rte = richTextEditorElement["ej2_instances"][0] as RichTextEditorComponent;
                console.log('rte', { rte }, rte.getHtml());
                if (IsNullOrWhiteSpace(rte.getHtml()) || rte.getHtml() === "<br>") {
                    return 'Required';
                }

            }

            return 'Required';
        }
    }
    return undefined;
};

export function isValidEmail(value: string): string | undefined {
    return /\S+@\S+\.\S+/.test(value) ? undefined : 'Invalid Email';
}

// export function hasEmoji1(value: string): string | undefined {
//     if (IsNotNullOrWhiteSpace(value)) {
//         const emojiRegex = /[\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{1F700}-\u{1F77F}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA00}-\u{1FA6F}]|[\u{1FA70}-\u{1FAFF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]/u;
//         let isEmojiRegex = emojiRegex.test(value);
//         if (isEmojiRegex) {
//             return `Invalid Character`;
//         } else {
//             return undefined;
//         }
//     } else {
//         return null;
//     }
// }

export function hasEmoji(value: string): string | undefined {
    if (IsNotNullOrWhiteSpace(value)) {
        // Simplified regex to match common emojis without using the `u` flag
        const emojiRegex = /(?:\uD83D[\uDE00-\uDE4F])|(?:\uD83C[\uDF00-\uDFFF])|(?:\uD83D[\uDC00-\uDDFF])/;
        let isEmojiRegex = emojiRegex.test(value);
        if (isEmojiRegex) {
            return `Invalid Character`;
        } else {
            return undefined;
        }
    } else {
        return null;
    }
}

export function setFormValue(rafForm: FormRenderProps, fieldName, value) {
    if (isNotNullAndUndefined(rafForm) && isNotNullAndUndefined(rafForm.form) && isNotNullAndUndefined(rafForm.form.mutators) && isNotNullAndUndefined(fieldName)) {
        rafForm.form.mutators.setValue(fieldName, value);
    }
}

export function getFormValue(rafForm: FormRenderProps, fieldName?: string) {
    if (isNotNullAndUndefined(rafForm) && isNotNullAndUndefined(rafForm.values)) {
        if (isNotNullAndUndefined(fieldName)) {
            return (rafForm.values[fieldName]);
        } else {
            return (rafForm.values);
        }
    }
    return null;
}

export function concatenateFormValues(rafForm: FormRenderProps, copyForm: {}, fields: string[]) {
    if (isNotNullAndUndefined(fields) && fields.length > 0 && isNotNullAndUndefined(copyForm)) {
        if (isNotNullAndUndefined(rafForm) && isNotNullAndUndefined(rafForm.form) && isNotNullAndUndefined(rafForm.form.mutators)) {
            fields.forEach((fieldName) => {
                rafForm.form.mutators.setValue(fieldName, copyForm[fieldName]);
            });
        }
    }
}

export function getArrayFormValue(rafForm: FormRenderProps, field: string, fieldName: string, index: number) {
    if (isNotNullAndUndefined(rafForm) && isNotNullAndUndefined(rafForm.values)) {
        if (isNotNullAndUndefined(field) && isNotNullAndUndefined(index) && isNotNullAndUndefined(fieldName)) {
            return (rafForm.values[field] && rafForm.values[field][index] && rafForm.values[field][index][fieldName]);
        } else if (isNotNullAndUndefined(field) && isNotNullAndUndefined(index)) {
            return (rafForm.values[field] && rafForm.values[field][index]);
        } else if (isNotNullAndUndefined(field)) {
            return (rafForm.values[field]);
        }
        return null;
    }
}

/*export const composeValidators = (...validators: any[]) => (value: string) =>
    validators.reduce((error, validator) => error || validator(value), undefined);*/

export const RAFFormContext = React.createContext(null);
export const FieldPrefixContext = React.createContext(null);

export interface RAFDetailFieldFormat {
    type: RAFDataType;
    format?: string;
}

export interface RAFFieldProps<T> {
    field: keyof T | string,
    label?: string,
    required?: boolean,
    validate?: boolean,
    showLabel?: boolean,
    width?: number | string,
    height?: number | string,
    placeholder?: string;
    textAreaColorCode?: string;
    icon?: string;
    disabled?: boolean;
    showClearButton?: boolean;
    cssClass?: string;
    htmlAttributes?: any;
    created?: (args) => void;
    onChanged?: (...args) => void;//label,value_for_lookup&dropdown -- color picker dropdown Value,label
    hideRequiredMessage?: boolean,
    labelClassName?: string,
    labelClass?: string,
    inputFieldClassName?: string,
    formGroupClassName?: string,
    validators?: any[],
    initialValue?: any;
    fieldInnerText?: string,
    readonly?: boolean;
    rowClassName?: string,
    maxlength?: string,
    description?: string,
    customLabel?: React.ReactFragment | ReactNode,
    unsetHeight?: boolean,
    descriptionAsLabel?: boolean;
    titleLocation?: string;
    componentCssClass?: string;
    labelRightSection?: React.ReactNode;
}

export interface RAFDetailFieldProps<T> {
    field: keyof T,
    emptyString?: string,
    fieldFormat?: RAFDetailFieldFormat,
    isSanitized?: boolean,
    valueClassName?: string,
}


export interface RAFTextBoxProps<T> extends RAFFieldProps<T> {
    onFocus?: (...args) => void;
    onInputBlur?: (...args) => void;
}

export interface RAFNumberProps<T> extends RAFFieldProps<T> {
    decimalsPoints?: number;
    minValue?: number;
    showSpinButton?: boolean;
    maxValue?: number;
    valueType?: "int" | "long";
}

export interface RAFTextAreaProps<T> extends RAFFieldProps<T> {
    rows?: number;
    onFocus?: (...args) => void;
    onInputBlur?: (...args) => void;
    onInputs?: (...args) => void;
    useMentions?: boolean;
    mentionsField?: string;
    mentionChar?: string;
    htmlEditorHeight?: string;
    // menuContainer?: string;
}

export interface RAFDropdownFieldProps<T> extends RAFFieldProps<T> {
    iconName?: string;
    uitype?: "colorpicker" | "default" | "smileyToggle";
    isColorOption?: boolean;
    moduleName?: string;
    allowAdd?: boolean;
    allowFiltering?: boolean;
    mode?: "discView" | "iconView" | "squareView";
    valueField?: 'DisplayName' | 'Name';
    textField?: 'DisplayName' | 'Name';
    createOptionMode?: "Footer" | "Default";
    popupWidth?: string;
    enableGroupByFormat?: boolean;
    valueJsonFilter?: {
        Field: keyof ValueJson;
        Value: string;
        Operator: RAFCustomOperator.Equal | RAFCustomOperator.NotEqual;
    };
    sortByTitle?: 'ASC' | 'DESC';
}

export interface RAFMultiSelectDropdownFieldProps<T> extends RAFFieldProps<T> {
    dropdownCheckboxDivClass?: string;
}

export interface RAFTextBoxWithIconFieldProps<T> extends RAFFieldProps<T> {
    iconCss?: string;
    customButtons?: React.ReactFragment | ReactNode;
}
export interface RAFCheckboxFieldProps<T> extends RAFFieldProps<T> {
    uitype?: "default" | "switch" | "eyeToggle" | "checkCircle" | "visibility" | "requiredToggle" | "readOnly" | "multiselect" | "btnToggle" | "favoriteToggle" | "pinToggle" | "checkIcon" | "lightCheckbox" | 'checkBoxInputNum';
    componentLabel?: string;
    labelPosition?: 'left' | 'right';
    colorStyle?: 'secondary';
}
export interface RAFFavoriteFieldProps<T> extends RAFCheckboxFieldProps<T> {
    enableToolTip?: boolean;
}

export interface RAFTagInputFieldProps<T> extends RAFFieldProps<T> {
    showAddBtn?: boolean;
    moduleName?: string;
}

export interface RAFIconPickerFieldProps<T> extends RAFFieldProps<T> {
    containerPosition?: "right" | "center" | "left" | "rightTop";
    iconPickerSize?: "sm" | "normal";
    removeRowClass?: boolean;
}

export interface RAFRadioButtonListFieldProps<T> extends RAFFieldProps<T> {
    uitype?: "default" | "userToggle" | "button" |
    "groupButton" |
    "yesNoToggle" | "customButton" | "customButtonWithColor" | "quickselect" | "smileyToggle" | 'customToggleButton' | 'tabButton';
    editorStyle?: 'Collapsible' | 'Default' | null;
    radioButtonSM?: boolean;
    labelPosition?: "left" | "right";
    radioBtnClassName?: string;
    radioBtnRowClassName?: string;
    groupBtnClassName?: string;
    colorStyle?: 'secondary';
}

export interface RAFDatePickerFieldProps<T> extends RAFFieldProps<T> {
    uitype?: "button" | "customButton" | "quickselect";
}
export interface RAFCheckBoxListFieldProps<T> extends RAFFieldProps<T> {
    uitype?: "default" | "vertical";
}

export interface RAFLookupFieldProps<T> extends RAFFieldProps<T> {
    type?: (typeof ILookupRow),
    textField?: string,
    valueField?: string,
    url: string,
    createform?: React.ReactNode | any,
    showFullList?: boolean,
    showCreateButton?: boolean,
    closeToolTip?: boolean,
    selectedField?: string,
    createformdefaultfield?: string,
    //createformaccountfield?: string,
    //createformaccountUIDfield?: string,
    customFilter?: RAFCustomFilter,
    createFormInitialValues?: { key: string, value?: any; }[],
    moduleName?: string;
    SearchCreateOptionMode?: "Footer" | "Default";
    addEmpty?: boolean;
    emptyString?: string;
    lookupGridURL?: string;
    additionalParams?: { key: string; value?: string | string[]; }[];
    disableItems?: LookUpRow[];
}

export interface RAFLookupGroupFieldProps<T> extends RAFFieldProps<T> {
    type: (typeof ILookupGroupRow),
    textField?: string,
    valueField?: string,
    groupField?: string,
    url?: string,
    createform?: any,
    createformdefaultfield?: string,
    customFilter?: RAFCustomFilter,
    moduleName?: string,
    showFullList?: boolean,
    showCreateButton?: boolean,
    selectedField?: string,
    createFormInitialValues?: { key: string, value?: any; }[],
    SearchCreateOptionMode?: "Footer" | "Default";
    closeToolTip?: boolean,
}

export const RAFDefaultFieldProps = {
    required: false,
    showLabel: true,
    disabled: false,
    showClearButton: true,
    validate: true,
};
export const RAFDefaultFieldClassName = {
    rowClassName: 'row gx-2 gx-md-3 gy-1',
    inputFieldClassName: 'col-12',
};

export interface RAFMediaFieldProps<T> extends RAFFieldProps<T> {
    url?: string;
    mediaHeight?: string;
    mediaWidth?: string;
}

export interface RAFStarRatingFieldProps<T> extends RAFFieldProps<T> {
    length?: number;
    allowSubmit?: boolean;
    autoSave?: boolean;
}

export const RAFDefaultDetailFieldProps = {
    emptyString: "",
    isSanitized: false,
    valueClassName: ""
};

export interface RAFFormProps<T> {
    initialValues?: T,
    primaryKey?: number | string,
    type?: (typeof RAFEntityBase);
    formRef?(e: FormRenderProps): FormRenderProps,
    layout?: RAFLayout.OneColumnLayout | RAFLayout.TwoColumnLayout | RAFLayout.ThreeColumnLayout | RAFLayout.FourColumnLayout,
    decorators?: Array<Decorator<any, Partial<any>>>;
    onSubmit?(e): void,
    onSave?(entityId?: string, objectName?: string): void,
    submitOnEnterKey?: boolean,
    convertBeforeSave?: Function,
    progressTarget?: string,
    className?: string,
    rules?: RuleProperties[];
    onChange?(values?: any): void,
}

export interface RAFChoiceOptionProps {
    label?: string,
    labelTemplate?: React.ReactFragment | ReactNode,
    value: number | string | boolean | Guid;
    category?: string;
    colorCode?: string;
    IconCss?: string;
    Icon?: string;
    disabled?: boolean;
}

export type WrappedProps2<P = {}> = {
    as2?: ComponentType<P> | ElementType;
} & P;

export const EvaluateRAFFormRules = (objectData, ruleActions: RAFRuleAction[]) => {
    if (isNotNullAndUndefined(ruleActions) && ruleActions.length > 0) {
        // for (let i = 0; i < ruleActions.length; i++) {
        //     let ruleAction = ruleActions[i];
        for (const ruleAction of ruleActions) {
            let rruleModel: RuleModel = getSFRulesByRAFRRule(ruleAction.RRuleModel);
            const isValid = validateRAFFormRulesForObject(objectData, rruleModel);
            //console.log({ isValid });
            //console.log(ruleAction.Action);
            if (isNotNullAndUndefined(ruleAction.Action) && ruleAction.Action.length > 0) {
                //for (let j = 0; j < ruleAction.Action.length; j++) {
                //let objAction = ruleAction.Action[j];
                for (const objAction of ruleAction.Action) {
                    switch (objAction.Type) {
                        case RAFBusinessAction.showField:
                            if (isValid === true) {
                                ShowElement(`#formItemDiv_${objAction.Value}`);
                            }
                            else {
                                HideElement(`#formItemDiv_${objAction.Value}`);
                            }
                            break;
                        case RAFBusinessAction.hideField:
                            if (isValid === true) {
                                HideElement(`#formItemDiv_${objAction.Value}`);
                            }
                            else {
                                ShowElement(`#formItemDiv_${objAction.Value}`);
                            }
                            break;
                        default:
                            break;
                    }
                }
            }

        }
    }

};

const validateRAFFormRulesForObject = (objectData, importRules: RuleModel): boolean => {
    const objDataArray = [];
    objDataArray.push(objectData);
    let qryBldrObj: QueryBuilder = new QueryBuilder({
        width: '70%',
        dataSource: objDataArray,
        //columns: columnData,
        rule: importRules,
    });
    let validRule: RuleModel = qryBldrObj.getValidRules(qryBldrObj.rule);
    let dataManagerQuery: Query = new Query()
        //.select(['TaskID', 'Category', 'Status'])
        .where(qryBldrObj.getPredicate(validRule))
        //.take(8)
        ;
    let result: Object[] = new DataManager(objDataArray).executeLocal(dataManagerQuery);
    //console.log('EvaluateRAFFormRules', { result });
    return (isNotNullAndUndefined(result) && result.length > 0);
};

export const getFormatedDate = (dateValue: Date, format: string) => {
    // let dateFormat
    // if (format === 'Date') {
    //     dateFormat = RAFDatePickerViewFormat.DATE;
    // }
    // else if (format === 'DateTime') {
    //     dateFormat = RAFDatePickerViewFormat.DATETIME;
    // }
    let value: any = convertUTCDateToLocalTimezone(dateValue);
    if (isNotNullAndUndefined(value) && value.toString() === 'Invalid Date') {
        return null;
    } else if (isNotNullAndUndefined(value)) {
        value = moment(value).format(format);
        return value;
    } else {
        return null;
    }
};

export const checkAndRenderContent = (content: any): React.ReactNode => {
    if (typeof content === 'string' || typeof content === 'number' || typeof content === 'boolean' || React.isValidElement(content)) {
        return content;
    }
    return null;
};

export const isValidReactNode = (value: any): value is React.ReactNode => {
    return typeof value === 'string' ||
        typeof value === 'number' ||
        typeof value === 'boolean' ||
        React.isValidElement(value);
};