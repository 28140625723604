import { RadioButtonComponent } from "@syncfusion/ej2-react-buttons";
import React, {
  PropsWithChildren,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { FormRenderProps } from "react-final-form";
import { Frequency, RRule, Weekday } from "rrule";
import { ConvertSchedulerToCronExpression } from "../../RAFMaster/helpers/RMutils";
import { getFormElementByAttribute } from "../../RAFModules/Common/RAFFieldHelper";
import { RAFAttributesContext } from "../Providers/RAFAttributeRelatedListProvider";
import { FrequencyValueType, RRDateTypes, RRDays, RRTimeOptions, getFrequencyOrigOptions } from "../helpers/RRuleInputHelper";
import {
  isNotEmptyArray,
  isNotNullAndUndefined,
  stringInclues,
} from "../helpers/utils";
import RAFDatePicker from "./RAFDatePicker";
import RAFDropdownCC from "./RAFDropdownCC";
import RAFFieldLabel from "./RAFFieldLabel";
import { ConditionIncludes } from "./RAFForm";
import {
  RAFDefaultFieldProps,
  RAFFormContext,
  RAFTextBoxProps,
  getFormValue,
  setFormValue,
} from "./RFFUtils";

interface IProps {
  rRuleExpressionPropertyName?: string;
}

interface IState {
  frequencyType: Frequency;
  byweekday: Weekday[];
  sessionType: string;
  //startDate: Date;
  //endDate: Date;
  endDateType: "Never" | "Until";
}

function RAFRRuleInput<T>({
  rRuleExpressionPropertyName = "RruleExpression",
  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  ...props
}: PropsWithChildren<IProps & RAFTextBoxProps<T>>) {
  const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);
  const rafAttributesContext = useContext(RAFAttributesContext);

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      frequencyType: RRule.DAILY,
      sessionType: isNotNullAndUndefined(
        getFormValue(rafFormContextValue, "Session")
      )
        ? "SESSIONS"
        : "ANYTime",
      byweekday: [],
      // startDate: new Date(),
      // endDate: null,
      endDateType: "Never",
    }
  );

  useEffect(() => {
    setInitialStateValue();
  }, []);

  const setInitialStateValue = () => {
    const fieldValue = getFormValue(
      rafFormContextValue,
      rRuleExpressionPropertyName
    );
    if (isNotNullAndUndefined(fieldValue)) {
      const options = getFrequencyOrigOptions(fieldValue);
      if (isNotNullAndUndefined(options)) {
        const { until, freq, byweekday } = options;
        setState({
          //startDate: dtstart,
          //endDate: until,
          frequencyType: freq,
          byweekday: byweekday as Weekday[],
          endDateType: isNotNullAndUndefined(until) ? "Until" : "Never",
        });
      }
    } else {
      const endDate = getFormValue(rafFormContextValue, "EndDate");
      const session = getFormValue(rafFormContextValue, "Session");
      setState({
        endDateType: isNotNullAndUndefined(endDate) ? "Until" : "Never",
        sessionType: isNotNullAndUndefined(session) ? "SESSIONS" : "ANYTime",
      });
    }
  };

  const onChangeSessionType = (value) => {
    if (value !== "SESSIONS") {
      setFormValue(rafFormContextValue, "Session", null);
    }
    setState({ sessionType: value });
  };

  const onChangeEndDateType = (value) => {
    setState({ endDateType: value });
    if (value === "Never") {
      setFormValue(rafFormContextValue, "EndDate", null);
    }
  };

  const onChangeByWeekday = (value) => {
    const { frequencyType } = state;
    const startDate = getFormValue(rafFormContextValue, "StartDate");
    const endDate = getFormValue(rafFormContextValue, "EndDate");
    if (isNotNullAndUndefined(value)) {
      let byweekday = isNotEmptyArray(state.byweekday) ? state.byweekday : [];
      let isItemExists = isNotEmptyArray(byweekday)
        ? stringInclues(state.byweekday, value)
        : false;
      if (isItemExists === false) {
        byweekday.push(value);
      } else if (isItemExists === true) {
        byweekday = byweekday.filter((x) => x !== value);
      }
      setState({ byweekday });
      updateRRuleValue(frequencyType, byweekday, startDate, endDate);
    } else {
      setState({ byweekday: [] });
      updateRRuleValue(frequencyType, [], startDate, endDate);
    }
  };

  const updateRRuleValue = (frequencyType: Frequency, byweekday: Weekday[], startDate: Date, endDate: Date) => {
    const rule = new RRule({
      dtstart: isNotNullAndUndefined(startDate) ? new Date(startDate) : null,
      until: isNotNullAndUndefined(endDate) ? new Date(endDate) : null,
      freq: isNotNullAndUndefined(frequencyType) ? frequencyType : RRule.DAILY,
      wkst: RRule.MO,
      byweekday: frequencyType === RRule.WEEKLY ? byweekday : null,
      interval: 1,
    });

    const cronExpression = ConvertSchedulerToCronExpression(rule.toString());
    const ruleString = rule.toString();
    setFormValue(
      rafFormContextValue,
      rRuleExpressionPropertyName,
      ruleString
    );
    setFormValue(rafFormContextValue, "CronExpression", cronExpression);
  };

  const onChangeFrequencyInputValue = (label) => {
    if (
      label === FrequencyValueType.Daily ||
      label === FrequencyValueType.Weekly
    ) {
      let frequencyType =
        label === FrequencyValueType.Daily ? RRule.DAILY : RRule.WEEKLY;
      const byweekday =
        frequencyType === RRule.WEEKLY && isNotEmptyArray(state.byweekday)
          ? state.byweekday
          : [];
      setFormValue(rafFormContextValue, "StartDate", new Date());
      const endDate = getFormValue(rafFormContextValue, "EndDate");
      setState({ byweekday, frequencyType });
      updateRRuleValue(frequencyType, byweekday, new Date(), endDate);
    } else {
      setFormValue(rafFormContextValue, "Session", null);
      setFormValue(
        rafFormContextValue,
        rRuleExpressionPropertyName,
        null
      );
      setFormValue(
        rafFormContextValue,
        'CronExpression',
        null
      );
      setFormValue(rafFormContextValue, "StartDate", null);
      setFormValue(rafFormContextValue, "EndDate", null);
      setState({
        byweekday: [],
        frequencyType: Frequency.DAILY,
        sessionType: "ANYTime",
      });
    }
  };

  const onChangeStartDateInputValue = (startDate: Date) => {
    const endDate = getFormValue(rafFormContextValue, "EndDate");
    updateRRuleValue(state.frequencyType, state.byweekday, startDate, endDate);
  };

  const onChangeEndDateInputValue = (endDate: Date) => {
    const startDate = getFormValue(rafFormContextValue, "StartDate");
    updateRRuleValue(state.frequencyType, state.byweekday, startDate, endDate);
  };

  const getStartDateContent = () => {
    const queryAttributes =
      isNotNullAndUndefined(rafAttributesContext) &&
        isNotNullAndUndefined(rafAttributesContext.queryAttributes)
        ? rafAttributesContext.queryAttributes
        : [];
    const startDatesessionAttribute = isNotEmptyArray(queryAttributes)
      ? queryAttributes.find((x) => x.AttributeName === "start_date")
      : null;
    if (isNotNullAndUndefined(startDatesessionAttribute)) {
      return (
        <RAFDatePicker
          field={'StartDate'}
          label={'Start Date'}
          required={startDatesessionAttribute.IsRequired}
          onChanged={onChangeStartDateInputValue}
        />
      );
    } else {
      return null;
    }
  };

  const getEndDateContent = () => {
    const queryAttributes =
      isNotNullAndUndefined(rafAttributesContext) &&
        isNotNullAndUndefined(rafAttributesContext.queryAttributes)
        ? rafAttributesContext.queryAttributes
        : [];
    const endDatesessionAttribute = isNotEmptyArray(queryAttributes)
      ? queryAttributes.find((x) => x.AttributeName === "end_date")
      : null;
    if (isNotNullAndUndefined(endDatesessionAttribute)) {
      return (
        <RAFDatePicker
          field={'EndDate'}
          label={'End Date'}
          required={endDatesessionAttribute.IsRequired}
          onChanged={onChangeEndDateInputValue}
        />
      );
    } else {
      return null;
    }
  };

  const getFrequencyContent = () => {
    const queryAttributes =
      isNotNullAndUndefined(rafAttributesContext) &&
        isNotNullAndUndefined(rafAttributesContext.queryAttributes)
        ? rafAttributesContext.queryAttributes
        : [];
    const sessionAttribute = isNotEmptyArray(queryAttributes)
      ? queryAttributes.find((x) => x.AttributeName === "frequency")
      : null;
    if (isNotNullAndUndefined(sessionAttribute)) {
      return (
        <RAFDropdownCC
          key={sessionAttribute.DisplayName}
          field={sessionAttribute.DisplayName}
          label={sessionAttribute.DisplayName}
          required={sessionAttribute.IsRequired}
          showLabel
          uitype="colorpicker"
          displayDropdownType="customDropdownBtnForMinCount"
          isColorOption
          queryAttribute={{
            AttributeUID: sessionAttribute.AttributeUID,
            DataType: sessionAttribute.DataType,
            DisplayName: sessionAttribute.DisplayName,
            IsMultiSelect: sessionAttribute.IsMultiSelect,
            RelatedEntities: sessionAttribute.RelatedEntities,
            UIType: sessionAttribute.UIType,
            ValueJson: sessionAttribute.ValueJson,
          }}
          addEmpty={false}
          formGroupClassName={"mb-0"}
          onChanged={(label) => {
            onChangeFrequencyInputValue(label);
          }}
        />
      );
    } else {
      return null;
    }
  };

  const getFrequencyContent1 = () => {
    const queryAttributes =
      isNotNullAndUndefined(rafAttributesContext) &&
        isNotNullAndUndefined(rafAttributesContext.queryAttributes)
        ? rafAttributesContext.queryAttributes
        : [];
    const sessionAttribute = isNotEmptyArray(queryAttributes)
      ? queryAttributes.find((x) => x.AttributeName === "frequency")
      : null;
    if (isNotNullAndUndefined(sessionAttribute)) {
      return getFormElementByAttribute(
        null,
        sessionAttribute,
        null,
        false,
        null,
        null,
        null,
        true,
        true,
        "Create"
        //onChangeFrequencyInputValue
      );
    } else {
      return null;
    }
  };

  const getSessionContent = () => {
    if (state.sessionType === "SESSIONS") {
      const queryAttributes =
        isNotNullAndUndefined(rafAttributesContext) &&
          isNotNullAndUndefined(rafAttributesContext.queryAttributes)
          ? rafAttributesContext.queryAttributes
          : [];
      const sessionAttribute = isNotEmptyArray(queryAttributes)
        ? queryAttributes.find((x) => x.AttributeName === "session")
        : null;
      if (isNotNullAndUndefined(sessionAttribute)) {
        return getFormElementByAttribute(
          null,
          sessionAttribute,
          null,
          false,
          null,
          null,
          null,
          true,
          true,
          "Create"
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  return (
    <div className="row gy-3">
      {getFrequencyContent()}
      <ConditionIncludes
        when={"Frequency"}
        is={[FrequencyValueType.Daily, FrequencyValueType.Weekly]}
      >
        <>
          {getStartDateContent()}
          {state.frequencyType === RRule.WEEKLY && (
            <div className="col-12">
              <div className="row">
                <RAFFieldLabel
                  field={props.field}
                  label={"Select days"}
                ></RAFFieldLabel>
                <div className="col-12">
                  <div className="row g-2">
                    {RRDays.map((item) => {
                      return (
                        <div className="col-auto" key={item.id}>
                          <button
                            type="button"
                            onClick={() => onChangeByWeekday(item.value)}
                            className={
                              isNotEmptyArray(state.byweekday) &&
                                stringInclues(state.byweekday, item.value)
                                ? "btn toggle-btn"
                                : "btn toggle-btn e-outline"
                            }
                          >
                            {item.text}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="col-12">
            <div className="row">
              <RAFFieldLabel
                field={props.field}
                label={"Select Time"}
              ></RAFFieldLabel>
              <div className="col-12">
                <div className="row g-2">
                  {RRTimeOptions.map((item) => {
                    return (
                      <div className="col-auto" key={item.id}>
                        <RadioButtonComponent
                          key={item.id}
                          name={"RRSelectTime"}
                          change={(e) => {
                            if (e.event.isTrusted) {
                              onChangeSessionType(e.value);
                            }
                          }}
                          label={item.text}
                          value={item.value}
                          checked={
                            item.value === state.sessionType ? true : false
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          {getSessionContent()}
          <div className="col-12">
            <div className="row">
              <RAFFieldLabel
                field={props.field}
                label={"End Date"}
              ></RAFFieldLabel>
              <div className="col-12">
                <div className="row g-2">
                  {RRDateTypes.map((item) => {
                    return (
                      <div className="col-auto" key={item.id}>
                        <RadioButtonComponent
                          key={item.id}
                          name={"EndDateType"}
                          change={(e) => {
                            if (e.event.isTrusted) {
                              onChangeEndDateType(e.value);
                            }
                          }}
                          label={item.text}
                          value={item.value}
                          checked={
                            item.value === state.endDateType ? true : false
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          {state.endDateType === "Until" && getEndDateContent()}
        </>
      </ConditionIncludes>
    </div>
  );
}

export default React.memo(RAFRRuleInput);
