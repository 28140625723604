import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { debounce } from "@syncfusion/ej2-base";
import {
  DialogComponent,
  TooltipComponent,
} from "@syncfusion/ej2-react-popups";
import * as React from "react";
import { Reducer, useContext, useEffect, useReducer } from "react";
import { Field, FormRenderProps } from "react-final-form";
import { getEntityByName } from "../../RAFMaster/helpers/RMutils";
import {
  Constants
} from "../../constants/Common/Constants";
import { PreventFocusOnDialogOpen } from "../Dialog/SFDialogUtils";
import { createInstance } from "../Utility/FormUtility";
import {
  getLookUpItems,
  getLookUpObjectByUID,
  getSearchedLookUpItems,
  getUniqueFilteredLookUpDropdownItems,
} from "../helpers/AutoCompleteMUIHelper";
import {
  getDefaultFieldNameByModule,
  getDisplayNameByModuleName,
} from "../helpers/RAFMenuHelper";
import {
  IsNotNullOrWhiteSpace,
  IsNullOrWhiteSpace,
  isEmptyArray,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../helpers/utils";
import { ILookupRow } from "../models/CompositeTypes/ILookupRow";
import { LookUpRow } from "../models/CompositeTypes/LookUpRow";
import RAFFieldLabel from "./RAFFieldLabel";
import { RAFFieldError, composeValidators } from "./RAFForm";
import RAFLookupGrid from "./RAFLookupGrid";
import {
  RAFDefaultFieldClassName,
  RAFDefaultFieldProps,
  RAFFormContext,
  RAFLookupFieldProps,
  hasEmoji,
  isRequired,
  setFormValue,
} from "./RFFUtils";

interface IState {
  isLoading: boolean;
  dropdownState: boolean;
  dataSource: LookUpRow[];
  searchedText: string;
  prevSearchedText: string;
  skipItemsOnScroll: number;

  //create dlg
  initialValues: {};
  showCreateContent?: boolean;
  showLookupGridContent?: boolean;
  createDialogHeader?: string;
}

const filter = createFilterOptions();

function RAFLookUpMUI<T>({
  field,
  label,
  width,
  created,
  onChanged,
  fieldInnerText,
  children,
  maxlength,
  description,
  descriptionAsLabel,
  titleLocation,

  closeToolTip = true,
  SearchCreateOptionMode = "Default",

  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  ...props
}: React.PropsWithChildren<RAFLookupFieldProps<T>>) {
  const allowCreateEmptyItem =
    props.showCreateButton === true && isNotNullAndUndefined(props.createform)
      ? true
      : false;

  const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);

  const objLookupRow = isNotNullAndUndefined(props.type)
    ? (createInstance(props.type) as ILookupRow)
    : null;

  const url = isNotNullAndUndefined(props.url)
    ? (props.url.startsWith('http') ? props.url : Constants.baseAPIUrl + props.url)
    : Constants.baseAPIUrl + objLookupRow.getLookupUrl();

  const showFullList =
    props.showFullList === false ? false :
      disabled === true
        ? false
        : isNotNullAndUndefined(props.showFullList) &&
        isNotNullAndUndefined(props.moduleName);

  //style classNames start
  let labelClassName = isNotNullAndUndefined(props.labelClassName)
    ? props.labelClassName
    : "";
  let rowClassName = isNotNullAndUndefined(props.rowClassName)
    ? `${props.rowClassName} row`
    : RAFDefaultFieldClassName.rowClassName;
  let inputFieldClassName = isNotNullAndUndefined(props.inputFieldClassName)
    ? props.inputFieldClassName
    : "col-12";
  if (titleLocation === "Right") {
    rowClassName = `${rowClassName} flex-nowrap gx-2`;
    labelClassName = "col-auto order-last";
    inputFieldClassName = "col";
  } else if (titleLocation === "Left") {
    rowClassName = `${rowClassName} flex-nowrap gx-2`;
    labelClassName = "col-3";
    inputFieldClassName = "col";
  } else if (titleLocation === "Bottom") {
    labelClassName = "order-last";
  }
  //style classNames end

  const defaultTake = 50;
  const defaultSkip = 0;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: false,
      dropdownState: false,
      dataSource: [],
      searchedText: null,
      skipItemsOnScroll: 0,

      //create dlg
      initialValues: null,
      showCreateContent: false,
      showLookupGridContent: false,
      createDialogHeader: null,
      prevSearchedText: null,
    }
  );

  useEffect(() => {
    refresh();
  }, [props.additionalParams, props.customFilter]);

  const refresh = () => {
    setState({
      dropdownState: false,
      dataSource: [],
      searchedText: null,
      prevSearchedText: null,
      skipItemsOnScroll: 0,
    });
  };

  const onOpenAutoDropdown = () => {
    setState({ dropdownState: true, skipItemsOnScroll: 0 });
    getLookUpDroopDownItems();
  };

  const getLookUpDroopDownItems = async () => {
    if (isNotEmptyArray(state.dataSource)) {
    } else {
      if (isNotNullAndUndefined(state.searchedText)) {
        return;
      }
      setState({ isLoading: true });

      const lookUpDataItems = await getLookUpItems(
        url,
        defaultSkip,
        defaultTake,
        null,
        props.moduleName,
        props.customFilter,
        props.additionalParams
      );

      if (
        isNotEmptyArray(lookUpDataItems)
      ) {
        const data: LookUpRow[] = lookUpDataItems;

        const uniqueData = getUniqueFilteredLookUpDropdownItems(data);

        const emptyState = {
          Value: `Add New Item`,
          inputValue: `Add New Item`,
        };

        if (isEmptyArray(uniqueData) && allowCreateEmptyItem === true) {
          uniqueData.push(emptyState);
        }

        const dropdownState = isNotEmptyArray(uniqueData) ? true : false;
        setState({ dataSource: uniqueData, dropdownState, isLoading: false });
      } else {
        setState({ dropdownState: false, dataSource: [], isLoading: false });
      }
    }
  };

  const loadMoreOptions = async () => {
    const { skipItemsOnScroll, searchedText, dataSource } = state;
    const newSkipItemsOnScroll = skipItemsOnScroll + defaultTake;
    if (
      isNotEmptyArray(dataSource) &&
      newSkipItemsOnScroll > dataSource.length
    ) {
      return;
    }
    const responseLookUpItems = await getSearchedLookUpItems(
      url,
      newSkipItemsOnScroll,
      defaultTake,
      searchedText,
      props.moduleName,
      props.customFilter,
      state.dataSource,
      state.prevSearchedText,
      props.additionalParams
    );

    const lookUpItems = isNotNullAndUndefined(responseLookUpItems) ? responseLookUpItems.lookUpDataItems : [];

    const responseData: LookUpRow[] = isNotEmptyArray(lookUpItems)
      ? [...lookUpItems]
      : [];

    const data = [...dataSource, ...responseData];

    const uniqueData = getUniqueFilteredLookUpDropdownItems(data);

    const emptyState = {
      Value: `Add "${searchedText}"`,
      inputValue: searchedText,
    };

    if (isNotEmptyArray(uniqueData)) {
      const searchItemExist =
        IsNotNullOrWhiteSpace(searchedText) &&
          isNotNullAndUndefined(
            uniqueData.find(
              (x) =>
                isNotNullAndUndefined(x.Value) &&
                x.Value.toLowerCase() === searchedText.toLowerCase()
            )
          )
          ? true
          : false;

      if (
        searchItemExist === false &&
        IsNotNullOrWhiteSpace(searchedText) &&
        allowCreateEmptyItem === true
      ) {
        uniqueData.push(emptyState);
      }

      setState({
        skipItemsOnScroll: newSkipItemsOnScroll,
        dataSource: uniqueData,
        prevSearchedText: searchedText,
        searchedText,
        isLoading: false,
      });
    } else if (
      allowCreateEmptyItem === true &&
      IsNotNullOrWhiteSpace(searchedText)
    ) {
      setState({
        skipItemsOnScroll: newSkipItemsOnScroll,
        dataSource: [emptyState],
        prevSearchedText: searchedText,
        searchedText,
        isLoading: false,
      });
    } else {
      setState({
        skipItemsOnScroll: newSkipItemsOnScroll,
        dataSource: [],
        prevSearchedText: searchedText,
        searchedText,
        dropdownState: false,
        isLoading: false,
      });
    }
  };

  const handleInputChange = (event, value) => {
    //calls when text input changes
    if (
      isNotNullAndUndefined(event) &&
      event.isTrusted === true &&
      event.type === "change"
    ) {
      setHandleInputDebounce(value);
    }
  };

  const setHandleInputDebounce = debounce(async (newSearchedText) => {
    setState({ isLoading: true, skipItemsOnScroll: defaultSkip });
    const searchedText = newSearchedText;

    const responseLookUpItems = await getSearchedLookUpItems(
      url,
      defaultSkip,
      defaultTake,
      searchedText,
      props.moduleName,
      props.customFilter,
      state.dataSource,
      state.prevSearchedText
    );

    const lookUpItems = isNotNullAndUndefined(responseLookUpItems) ? responseLookUpItems.lookUpDataItems : [];

    const data: LookUpRow[] = isNotEmptyArray(lookUpItems)
      ? [...lookUpItems]
      : [];

    const uniqueData = getUniqueFilteredLookUpDropdownItems(data);

    const emptyState = {
      Value: `Add "${searchedText}"`,
      inputValue: searchedText,
    };

    if (isNotEmptyArray(uniqueData)) {
      const searchItemExist =
        IsNotNullOrWhiteSpace(searchedText) &&
          isNotNullAndUndefined(
            uniqueData.find(
              (x) =>
                isNotNullAndUndefined(x.Value) &&
                x.Value.toLowerCase() === searchedText.toLowerCase()
            )
          )
          ? true
          : false;

      if (
        searchItemExist === false &&
        IsNotNullOrWhiteSpace(searchedText) &&
        allowCreateEmptyItem === true
      ) {
        uniqueData.push(emptyState);
      }

      setState({
        dataSource: uniqueData,
        prevSearchedText: searchedText,
        searchedText,
        isLoading: false,
      });
    } else if (
      allowCreateEmptyItem === true &&
      IsNotNullOrWhiteSpace(searchedText)
    ) {
      setState({
        dataSource: [emptyState],
        prevSearchedText: searchedText,
        searchedText,
        isLoading: false,
      });
    } else {
      setState({
        dataSource: [],
        prevSearchedText: searchedText,
        searchedText,
        dropdownState: false,
        isLoading: false,
      });
    }
  }, 500);

  const onChange = (newValue: LookUpRow, input, newCreateValue?: string) => {
    const itemUID = isNotNullAndUndefined(newValue) ? newValue.UID : null;
    const itemValue = isNotNullAndUndefined(newValue) ? newValue.Value : null;
    setFormValue(rafFormContextValue, field.toString() + "UID", itemUID);
    input.onChange(itemValue !== null ? itemValue : null);
    if (isNotNullAndUndefined(onChanged)) {
      onChanged(itemValue, itemUID);
    }
    if (IsNotNullOrWhiteSpace(newCreateValue) && allowCreateEmptyItem) {
      setState({ searchedText: newCreateValue });
      openCreateDialog();
    }
  };

  const onCloseAutoDropdown = () => {
    setState({
      skipItemsOnScroll: 0,
      dropdownState: false,
      isLoading: false,
      searchedText: null,
      prevSearchedText: null,
      dataSource: IsNotNullOrWhiteSpace(state.searchedText)
        ? []
        : state.dataSource,
    });
  };

  // create dialog starts

  const openCreateDialog = () => {
    getEntityByName(props.moduleName).then(async (entity) => {
      let initialValues = {};
      if (isNotNullAndUndefined(props.createformdefaultfield)) {
        initialValues[props.createformdefaultfield] = state.searchedText;
      } else {
        let defaultField = await getDefaultFieldNameByModule(props.moduleName);
        initialValues[defaultField] = state.searchedText;
      }

      const createFormInitialValues = props.createFormInitialValues;
      if (isNotNullAndUndefined(createFormInitialValues)) {
        createFormInitialValues.forEach((item) => {
          initialValues[item.key] = item.value;
        });
      }

      setState({
        showCreateContent: true,
        initialValues: initialValues,
        createDialogHeader:
          getDisplayNameByModuleName(
            props.moduleName,
            isNotNullAndUndefined(entity) ? entity.DisplayName : null
          ) ?? "Item",
      });
    });
  };

  const createFormContent = () => {
    if (
      state.showCreateContent &&
      isNotNullAndUndefined(state.initialValues) &&
      isNotNullAndUndefined(props.createform)
    ) {
      const Createform1 = props.createform;
      let initialValues = state.initialValues;

      return (
        <div>
          <Createform1
            as2={props.createform}
            isActive
            moduleName={props.moduleName}
            onSave={refreshLookupControl.bind(this)}
            onClose={() => closeCreateDialog()}
            {...(initialValues ? { initialValues: initialValues } : {})}
          ></Createform1>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const closeCreateDialog = () => {
    setState({
      initialValues: null,
      showCreateContent: false,
      createDialogHeader: "",
    });
  };

  const refreshLookupControl = (entityId, objectName) => {
    setState({
      initialValues: null,
      showCreateContent: false,
      showLookupGridContent: false,
    });

    //setFormValue(rafFormContextValue, field.toString(), objectName);
    //setFormValue(rafFormContextValue, field.toString() + "UID", entityId);

    const newCreatedItem = new LookUpRow();
    newCreatedItem.UID = entityId;
    newCreatedItem.Value = objectName;

    setState({
      searchedText: null,
      prevSearchedText: null,
      dropdownState: false,
      dataSource: [],
    });
    onCreateNewLookUpItem(newCreatedItem);
  };

  const onCreateNewLookUpItem = async (newCreatedItem: LookUpRow) => {
    if (
      isNotNullAndUndefined(newCreatedItem) &&
      isNotNullAndUndefined(newCreatedItem.Value)
    ) {
      setState({ isLoading: true });
      const searchedText = newCreatedItem.Value;

      const responseLookUpItems = await getSearchedLookUpItems(
        url,
        defaultSkip,
        defaultTake,
        searchedText,
        props.moduleName,
        props.customFilter,
        state.dataSource,
        state.prevSearchedText,
        props.additionalParams
      );

      const lookUpItems = isNotNullAndUndefined(responseLookUpItems) ? responseLookUpItems.lookUpDataItems : [];

      const data: LookUpRow[] = isNotEmptyArray(lookUpItems)
        ? [...lookUpItems]
        : [];

      const uniqueData = getUniqueFilteredLookUpDropdownItems(data);

      if (
        isNotNullAndUndefined(newCreatedItem) &&
        isNotNullAndUndefined(newCreatedItem.UID)
      ) {
        //check item exist in list
        const isItemExist = isNotEmptyArray(uniqueData)
          ? uniqueData.find((x) => x.UID === newCreatedItem.UID)
          : null;
        if (isNotNullAndUndefined(isItemExist)) {
          setFormValue(
            rafFormContextValue,
            field.toString(),
            newCreatedItem.Value
          );
          setFormValue(
            rafFormContextValue,
            field.toString() + "UID",
            newCreatedItem.UID
          );

          if (isNotNullAndUndefined(onChanged)) {
            onChanged(newCreatedItem.Value, newCreatedItem.UID);
          }
        }

        setState({ isLoading: false });
      } else {
        setState({ isLoading: false });
      }
    }
  };

  // create dialog ends

  //show lookup grid dialog starts
  const showLookUpGridDialogOpen = () => {
    setState({ showLookupGridContent: true });
  };

  const lookupGridContent = () => {
    let { showLookupGridContent } = state;
    if (
      showLookupGridContent === true &&
      isNotNullAndUndefined(props.moduleName)
    ) {
      return (
        <RAFLookupGrid
          rowclicked={(value) => setLookUpValue(value)}
          onClose={() => showLookupGridDialogClose()}
          customFilter={props.customFilter}
          moduleName={props.moduleName}
          lookupGridURL={props.lookupGridURL}
          mode="input"
          isActive
          {...props}
        />
      );
    } else {
      return <div></div>;
    }
  };

  const setLookUpValue = async (value) => {
    if (isNotNullAndUndefined(value)) {
      let objectName = !IsNullOrWhiteSpace(props.selectedField)
        ? value[props.selectedField]
        : null;
      let uid = value.UID;
      if (IsNullOrWhiteSpace(objectName)) {
        let defaultField = await getDefaultFieldNameByModule(props.moduleName);
        objectName = value[defaultField];
      }
      if (IsNullOrWhiteSpace(objectName)) {
        //when object name is not found in selected field and default field
        const objectRow = await getLookUpObjectByUID(
          url,
          uid,
          props.moduleName
        );
        objectName = isNotNullAndUndefined(objectRow) ? objectRow.Value : null;
      }
      if (isNotNullAndUndefined(uid) && isNotNullAndUndefined(objectName)) {
        setFormValue(rafFormContextValue, field.toString(), objectName);
        setFormValue(rafFormContextValue, field.toString() + "UID", uid);
        if (isNotNullAndUndefined(onChanged)) {
          onChanged(objectName, uid);
        }
      }
    }
    setState({ showLookupGridContent: false });
  };

  const showLookupGridDialogClose = () => {
    setState({ showLookupGridContent: false });
  };

  //show lookup grid dialog ends

  const getMUILookUpItems = (dataSource: LookUpRow[]) => {
    const { disableItems } = props;
    if (isNotEmptyArray(disableItems) && isNotEmptyArray(dataSource)) {
      const filteredDataSource = dataSource.filter(x =>
        !disableItems.some(disItem => disItem.UID === x.UID));
      return filteredDataSource;
    } else {
      return dataSource;
    }
  };

  return (
    <div
      className={
        isNotNullAndUndefined(props.formGroupClassName)
          ? props.formGroupClassName + " form-group"
          : "form-group"
      }
    >
      <div className={rowClassName} id={"rafdiv" + field.toString()}>
        {showLabel && showLabel === true && (
          <RAFFieldLabel
            field={field}
            label={label}
            required={required}
            labelClassName={labelClassName}
            description={description}
            descriptionAsLabel={descriptionAsLabel}
            rightSection={props.labelRightSection}
          ></RAFFieldLabel>
        )}
        <div className={inputFieldClassName}>
          <Field
            name={field.toString()}
            {...(props.initialValue
              ? { initialValue: props.initialValue }
              : {})}
            //validate={validate === true ? (required && isRequired) : null}
            {...(props.validators
              ? {
                validate:
                  validate === true
                    ? composeValidators(
                      required === true ? isRequired : null,
                      hasEmoji,
                      ...props.validators
                    )
                    : null,
              }
              : {
                validate:
                  validate === true
                    ? composeValidators(
                      required === true ? isRequired : null,
                      hasEmoji
                    )
                    : null,
              })}
            allowNull
            parse={(value) => (value === "" ? null : value)}
          >
            {({ input }) => {
              return (
                <div
                  className={`row g-0${showFullList === true ? " raf_mui_input_group" : ""
                    }${IsNotNullOrWhiteSpace(props.componentCssClass)
                      ? ` ${props.componentCssClass}`
                      : ""
                    }`}
                >
                  {showFullList === true && (
                    <div className="col-auto">
                      <div
                        onClick={showLookUpGridDialogOpen}
                        className="raf_mui_input_icon_div_outter"
                      >
                        <TooltipComponent
                          content="Show more"
                          position="BottomCenter"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M9.58333 17.5013C13.9556 17.5013 17.5 13.9569 17.5 9.58464C17.5 5.21238 13.9556 1.66797 9.58333 1.66797C5.21108 1.66797 1.66666 5.21238 1.66666 9.58464C1.66666 13.9569 5.21108 17.5013 9.58333 17.5013Z"
                              stroke="#F65C06"
                              strokeWidth={1.5}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M18.3333 18.3346L16.6667 16.668"
                              stroke="#F65C06"
                              strokeWidth={1.5}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </TooltipComponent>
                      </div>
                    </div>
                  )}
                  <div className="col">
                    <Autocomplete
                      id={input.name}
                      value={input.value}
                      open={state.dropdownState}
                      disabled={disabled === true ? true : false}
                      onOpen={() => {
                        onOpenAutoDropdown();
                      }}
                      onClose={() => {
                        onCloseAutoDropdown();
                      }}
                      options={getMUILookUpItems(state.dataSource)}
                      isOptionEqualToValue={(option, value) =>
                        option.Value === value
                      }
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.Value
                      }
                      getOptionKey={(option) =>
                        typeof option === "string" ? option : option.UID
                      }
                      className={`raf_mui_input${showFullList === true ? " left_icon" : ""
                        }`}
                      loading={state.isLoading}
                      onInputChange={handleInputChange}
                      onBlur={(event) => {
                        if (isNotNullAndUndefined(state.searchedText)) {
                          onCloseAutoDropdown();
                        }
                      }}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          //on enter key press
                          const existingItem =
                            IsNotNullOrWhiteSpace(newValue) &&
                              isNotEmptyArray(state.dataSource)
                              ? state.dataSource.find(
                                (x) =>
                                  IsNullOrWhiteSpace(x["inputValue"]) &&
                                  isNotNullAndUndefined(x.Value) &&
                                  x.Value.toLowerCase() ===
                                  newValue.toLowerCase()
                              )
                              : null;

                          if (isNotNullAndUndefined(existingItem)) {
                            onChange(existingItem, input);
                          } else {
                            onChange(null, input, newValue);
                          }
                        } else if (
                          allowCreateEmptyItem === true &&
                          isNotNullAndUndefined(newValue) &&
                          IsNotNullOrWhiteSpace(newValue.inputValue)
                        ) {
                          onChange(null, input, newValue.inputValue);
                        } else {
                          onChange(newValue, input);
                        }
                      }}
                      size="small"
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        // if (isNotNullAndUndefined(params) && IsNotNullOrWhiteSpace(params.inputValue) && allowCreateEmptyItem === true) {
                        //     const searchedText = params.inputValue;
                        //     const isFilteredItemExist = isNotEmptyArray(filtered) && IsNotNullOrWhiteSpace(searchedText) &&
                        //         isNotNullAndUndefined(filtered.find(x => (isNotNullAndUndefined(x['Value']) && x['Value'].toLowerCase() === searchedText.toLowerCase()))) ? true : false;

                        //     if (isNotEmptyArray(filtered)) {
                        //         filtered.push({
                        //             inputValue: params.inputValue,
                        //             Value: `Add "${params.inputValue}"`,
                        //         });
                        //     }
                        // }
                        return filtered;
                      }}
                      freeSolo
                      clearOnBlur
                      renderOption={(props, option) => (
                        <li {...props}>{option.Value}</li>
                      )}
                      autoFocus={false}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          //label={props.placeholder}
                          margin="normal"
                          variant="outlined"
                          placeholder={props.placeholder}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {state.isLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                      // ListboxComponent={(props) => (
                      //     <div {...props} style={{ position: 'relative' }}>
                      //         {props.children}
                      //         <div style={{ bottom: 0, width: '100%' }}>
                      //             <hr />
                      //             <div style={{ paddingInline: '8px', display: 'flex', justifyContent: 'space-between' }}>
                      //                 <div onClick={() => alert('Create clicked')}>Create</div>
                      //                 <div onClick={() => alert('Show Full List clicked')}>Show Full List</div>
                      //             </div>
                      //         </div>
                      //     </div>
                      // )}
                      ListboxProps={{
                        onScroll: (event) => {
                          const listboxNode = event.currentTarget;
                          if (
                            listboxNode.scrollTop + listboxNode.clientHeight ===
                            listboxNode.scrollHeight
                          ) {
                            // The scroll has hit the bottom, load more options
                            loadMoreOptions();
                          }
                        },
                      }}
                    />

                    {props.hideRequiredMessage !== true ? (
                      <RAFFieldError name={field.toString()} />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            }}
          </Field>
        </div>
      </div>
      <div>
        {state.showCreateContent && (
          <DialogComponent
            header={"Create " + state.createDialogHeader}
            visible={state.showCreateContent}
            cssClass="rightDialog createEditForm full-height form-center-dialog" //ref={dialog => showLookupGridDialog = dialog}
            content={createFormContent.bind(this)}
            showCloseIcon
            isModal
            target="body"
            closeOnEscape={false}
            close={closeCreateDialog.bind(this)}
            zIndex={1500}
            open={PreventFocusOnDialogOpen}
          />
        )}
      </div>
      <div>
        {state.showLookupGridContent && (
          <DialogComponent
            visible={state.showLookupGridContent}
            //cssClass="centerDialog-lg dlg-new-style fixed-height"
            cssClass="rightDialog rightDialog-lg createEditForm full-height form-center-dialog dlg-new-style"
            //ref={dialog => showLookupGridDialog = dialog}
            content={lookupGridContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={() => showLookupGridDialogClose.bind(this)}
            zIndex={1200}
            open={PreventFocusOnDialogOpen}
          />
        )}
      </div>
    </div>
  );
}

export default RAFLookUpMUI;
