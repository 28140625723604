import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import { PropsWithChildren, Reducer, useReducer } from 'react';
import { BrowserIsDevice, RAFButtonConstant } from '../../../constants/Common/Constants';
import RAFButtonComponent from '../../Navigation/RAFButtonComponent';
import { isNotEmptyArray, isNotNullAndUndefined } from '../../helpers/utils';
import { RiskScoreTransValueRow } from './RiskScoreTransRow';
import { RiskMatrixJsonRowNew } from './dataNew';
import './style.scss';

interface IProps {
  riskMatrixJsonRow: RiskMatrixJsonRowNew;
  selectedRiskScore: number;
  onClickCellData?: (riskScore: number, riskColorCode: string) => void;
  onClearRiskScoreMatrix: () => void;
  setCellData?: (riskScoreValue: RiskScoreTransValueRow) => void;
}

interface IState {
  selectedCategoryIndex: number;
}

const RiskMatrixJsonUI = ({
  riskMatrixJsonRow,
  ...props
}: PropsWithChildren<IProps>) => {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      selectedCategoryIndex: 0,
    }
  );

  const { Axes, Levels, Matrix } = riskMatrixJsonRow;

  const xAxisItems = Axes.filter((item) => item.Axis === "X");

  const getLevelByLevelValue = (level: number) => {
    const levelObj = Levels.find((l) => l.Level === level);
    return levelObj;
  };

  const onClickCellData = (
    riskScore: number,
    riskColorCode: string,
    riskLevel: number,
    riskLevelName: string,
    refId: number
  ) => {
    if (props.onClickCellData) {
      props.onClickCellData(riskScore, riskColorCode);
    }
    const riskScoreValue: RiskScoreTransValueRow = {
      Score: riskScore,
      Level: riskLevel,
      Color: riskColorCode,
      LevelName: riskLevelName,
      RefId: refId,
    };
    if (props.setCellData) {
      props.setCellData(riskScoreValue);
    }
  };

  const renderMatrix = () => {
    const rows = Axes[0].Values.map((yValue, yIndex) => {
      const cells = Axes[1].Values.map((xValue, xIndex) => {
        const cellData = Matrix.find(
          (item) => item.XIndex === xIndex && item.YIndex === yIndex
        );

        const levelObj = getLevelByLevelValue(cellData.Risk.Level);

        const levelName = isNotNullAndUndefined(levelObj)
          ? levelObj.Name
          : null;
        const levelBGColor = isNotNullAndUndefined(levelObj)
          ? `#${levelObj.Colour}`
          : null;

        //selectedRiskScore
        return (
          <td
            key={`${xIndex}-${yIndex}`}
            style={{
              background: levelBGColor,
              padding: "20px",
              textAlign: "center",
              cursor: "pointer",
              border: "1px solid #000",
            }}
            onClick={() =>
              onClickCellData(
                cellData.Risk.Score,
                levelBGColor,
                cellData.Risk.Level,
                levelName,
                levelObj.skytrust_ref_LevelID
              )
            }
          >
            <div>
              {isNotNullAndUndefined(cellData) ? cellData.Risk.Score : ""}{" "}
              {isNotNullAndUndefined(levelName) ? `-${levelName}` : ""}
            </div>
          </td>
        );
      });

      return (
        <tr key={yIndex}>
          <td>{yValue}</td> {/* Row header */}
          {cells}
        </tr>
      );
    });

    return rows;
  };

  const onSelectTabContent = (selectedCategoryIndex) => {
    setState({ selectedCategoryIndex });
  };

  const getCategoryTabContent = () => {
    const categoryTabItems = xAxisItems.map((item, index) => {
      return {
        value: item.Category,
        text: item.Category,
      };
    });
    if (isNotEmptyArray(categoryTabItems) && categoryTabItems.length > 1) {
      const tabClassName = !BrowserIsDevice
        ? `e-fill custom-tab-footer-border bp-tab active-bg-gray hideTabIcon tabHeader_secondaryHeader grey_tabHeader padding_tabHeader_sm borderBottom_tabHeader bg-white raf-tab-lg sticky__tabHeader`
        : `e-fill custom-tab-footer-border bp-tab active-bg-gray hideTabIcon raf-tab-lg tabHeader_secondaryHeader grey_tabHeader padding_tabHeader_sm borderBottom_tabHeader white_tabHeader`;

      return (
        <TabComponent
          id="defaultTab"
          className={tabClassName}
          heightAdjustMode="None"
          overflowMode={"Scrollable"}
          selectedItem={state.selectedCategoryIndex}
          selected={(e) => {
            if (e.isInteracted) {
              const selectedCategoryIndex = e.selectedIndex;
              onSelectTabContent(selectedCategoryIndex);
            }
          }}
          animation={{
            previous: {
              effect: "FadeIn",
              duration: 600,
              easing: "ease",
            },
            next: {
              effect: "FadeIn",
              duration: 600,
              easing: "ease",
            },
          }}
          selecting={onSelecting}
          loadOn="Demand"
        >
          <TabItemsDirective>
            {categoryTabItems.map((x) => {
              return (
                <TabItemDirective
                  key={`${x.value}`}
                  header={x}
                  content={tabItemsDirectiveContent}
                />
              );
            })}
          </TabItemsDirective>
        </TabComponent>
      );
    } else {
      return <></>;
    }
  };

  const onSelecting = (e) => {
    if (e.isSwiped) {
      e.cancel = true;
    }
  };

  const tabItemsDirectiveContent = () => {
    return <div className="hidden"></div>;
  };

  return (
    <div className="e-dlg-content-outer h-100">
      {getCategoryTabContent()}
      <div className="e-dlg-body-content">
        <div
          className="risk_matrix_container"
        //  style={{ display: 'grid', gridTemplateColumns: `repeat(${xAxisItems[state.selectedCategoryIndex].Values.length + 1}, 1fr)`, gap: '1px', width: '100%' }}
        >
          {/* Column headers */}
          <div></div> {/* Placeholder for the top-left corner */}
          {xAxisItems[state.selectedCategoryIndex].Values.map((value, idx) => (
            <div
              key={idx}
              className="risk_matrix_header"
            // style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold', border: '1px solid #000' }}
            >
              {value}
            </div>
          ))}
          {/* Matrix rows and cells */}
          {Axes[0].Values.map((yValue, yIndex) => {
            return (
              <>
                {/* Row header */}
                <div
                  key={`y-${yIndex}`}
                  className="risk_matrix_header"
                // style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold', border: '1px solid #000' }}
                >
                  {yValue}
                </div>

                {/* Cells for each row */}
                {Axes[1].Values.map((xValue, xIndex) => {
                  const cellData = Matrix.find(
                    (item) => item.XIndex === xIndex && item.YIndex === yIndex
                  );

                  const levelObj = getLevelByLevelValue(cellData.Risk.Level);
                  const levelName = isNotNullAndUndefined(levelObj)
                    ? levelObj.Name
                    : null;
                  const levelBGColor = isNotNullAndUndefined(levelObj)
                    ? `#${levelObj.Colour}`
                    : null;

                  return (
                    <div
                      key={`${xIndex}-${yIndex}`}
                      className={`risk_matrix_item${props.selectedRiskScore === cellData.Risk.Score ? ' active' : ''}`}
                      style={{
                        background: levelBGColor,
                        // padding: '20px',
                        // textAlign: 'center',
                        // cursor: 'pointer',
                        // border: '1px solid #000'
                      }}
                      onClick={() =>
                        onClickCellData(
                          cellData.Risk.Score,
                          levelBGColor,
                          cellData.Risk.Level,
                          levelName,
                          levelObj.skytrust_ref_LevelID
                        )
                      }
                    >
                      {isNotNullAndUndefined(cellData)
                        ? cellData.Risk.Score
                        : ""}
                      {isNotNullAndUndefined(levelName)
                        ? ` - ${levelName}`
                        : ""}
                    </div>
                  );
                })}
              </>
            );
          })}
        </div>

        {/* <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <thead>
              <tr>
                <th></th>
                {xAxisItems[state.selectedCategoryIndex].Values.map((value, idx) => (
                  <th key={idx}>{value}</th>
                ))}
              </tr>
            </thead>
            <tbody>{renderMatrix()}</tbody>
          </table> */}
      </div>
      {isNotNullAndUndefined(props.onClearRiskScoreMatrix) && (
        <div className="e-dlg-footerContent">
          <div className="w-100">
            <div className="row gx-2">
              <div className="col-auto ms-auto">
                <RAFButtonComponent
                  type="button"
                  isPrimary
                  action={RAFButtonConstant.Clear}
                  onClick={props.onClearRiskScoreMatrix}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RiskMatrixJsonUI;
