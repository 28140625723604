import { Checkbox, ListItemText, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import * as React from "react";
import { PropsWithChildren, Reducer, useReducer } from "react";
import { Field, FormRenderProps } from "react-final-form";
import {
  IsNotNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../../RAFComponents/helpers/utils";
import { getQueryAttribute } from "../helpers/AppHelper";
import { QueryAttributeJM, ValueJson } from "../models/Common/QueryAttributeJM";
import "./InputStyle.scss";
import RAFFieldLabel from "./RAFFieldLabel";
import { RAFFieldError, composeValidators } from "./RAFForm";
import { RAFDefaultFieldClassName, RAFDefaultFieldProps, RAFFormContext, RAFMultiSelectDropdownFieldProps, isRequiredForArray, setFormValue } from "./RFFUtils";

interface IProps {
  disableItems?: any[];
  allowFiltering?: boolean;
  moduleName?: string;
  allowAdd?: boolean;
  emptyString?: string;
  queryAttribute?: QueryAttributeJM;
  uitype?: "dropdown" | "checkbox";
  showMinimumItems?: number;
}

interface IState {
  queryAttribute: QueryAttributeJM;
}

const getDataFromChildren = (
  children?,
  addEmpty?: boolean,
  emptyString?: string,
  attributeJM?: QueryAttributeJM
) => {
  let retVal: ValueJson[] = [];
  if (isNotNullAndUndefined(children) && children.length > 0) {
    if (isNotNullAndUndefined(addEmpty) && addEmpty === true) {
      retVal.push({
        Id: 0,
        Name: null,
        DisplayName: emptyString || "None",
        ColorCode: "#B3B6B7",
      });
    }
    React.Children.forEach(children, (child, i) => {
      //retVal.push({ ...child["props"], label: (child["props"]["children"] || child["props"]["label"]) });
      if (IsNotNullOrWhiteSpace(child["props"]["value"])) {
        retVal.push({
          Id: i + 1,
          Name: child["props"]["value"],
          DisplayName: child["props"]["children"] || child["props"]["label"],
          ColorCode: child["props"]["colorCode"],
        });
      }
    });
  } else {
    if (isNotNullAndUndefined(addEmpty) && addEmpty === true) {
      retVal.push({
        Id: 0,
        Name: "",
        DisplayName: emptyString || "None",
        ColorCode: "transparent",
      });
    }
    if (isNotNullAndUndefined(attributeJM)) {
      if (isNotNullAndUndefined(attributeJM.ValueJson)) {
        retVal.push(...attributeJM.ValueJson);
      }
    }
  }
  return retVal;
};

function RAFMultiSelectDropdownMUI<T>({
  field,
  label,
  width,
  onChanged,
  children,
  disableItems,
  allowFiltering,
  description,
  descriptionAsLabel,
  titleLocation,
  showMinimumItems = 5,
  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  ...props
}: PropsWithChildren<RAFMultiSelectDropdownFieldProps<T> & IProps>) {

  const rafFormContextValue: FormRenderProps = React.useContext(RAFFormContext);

  let labelClassName = isNotNullAndUndefined(props.labelClassName)
    ? props.labelClassName
    : "";
  let rowClassName = isNotNullAndUndefined(props.rowClassName)
    ? `${props.rowClassName} row`
    : RAFDefaultFieldClassName.rowClassName;
  let inputFieldClassName = isNotNullAndUndefined(props.inputFieldClassName)
    ? props.inputFieldClassName
    : "col-12";
  if (titleLocation === "Right") {
    rowClassName = `${rowClassName} flex-nowrap gx-2`;
    labelClassName = "col-auto order-last";
    inputFieldClassName = "col";
  } else if (titleLocation === "Left") {
    rowClassName = `${rowClassName} flex-nowrap gx-2`;
    labelClassName = "col-3";
    inputFieldClassName = "col";
  } else if (titleLocation === "Bottom") {
    labelClassName = "order-last";
  }

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      queryAttribute: null,
    }
  );

  React.useEffect(() => {
    const fetchDropdowItems = () => {
      if (isNullOrUndefined(props.queryAttribute)) {
        getQueryAttribute(props.moduleName, field.toString())
          .then((queryAttribute) => {
            setState({ queryAttribute: queryAttribute });
          })
          .catch((error) => error);
      } else {
        setState({ queryAttribute: props.queryAttribute });
      }
    };

    fetchDropdowItems();
  }, [props.queryAttribute]);

  const items = getDataFromChildren(
    React.Children.toArray(children),
    false,
    "None",
    state.queryAttribute
  );

  const handleChange = (event: SelectChangeEvent<string[]>, input) => {

    const targetValue = event.target.value as string[];

    const newValue = isNotEmptyArray(targetValue) ? targetValue : [];

    const myArrayFiltered = items.filter((el) => {
      if (isNotEmptyArray(newValue)) {
        return newValue.some((f) => {
          return f === el.DisplayName || f === el.Name;
        });
      }
    });

    const selectedItems = isNotEmptyArray(myArrayFiltered) ? myArrayFiltered.map((e) => {
      return e.Name;
    }) : [];

    input.onChange(selectedItems);
    if (onChanged) {
      onChanged(selectedItems);
    }
  };

  const onChangeSelectAllCheckBox = (isChecked: boolean) => {
    if (isChecked) {
      const allValues = isNotEmptyArray(items) ? items.map((e) => e.Name) : [];
      setFormValue(rafFormContextValue, field.toString(), allValues);
      if (onChanged) {
        onChanged(allValues);
      }
    } else {
      setFormValue(rafFormContextValue, field.toString(), []);
      if (onChanged) {
        onChanged([]);
      }
    }
  };

  return (
    <Field
      name={field.toString()}
      {...(isNotEmptyArray(props.initialValue)
        ? { initialValue: props.initialValue }
        : {})}
      //validate={validate === true ? (required && isRequired) : null}
      {...(props.validators
        ? {
          validate:
            validate === true
              ? composeValidators(
                required === true ? isRequiredForArray : null,
                ...props.validators
              )
              : null,
        }
        : {
          validate:
            validate === true
              ? composeValidators(
                required === true ? isRequiredForArray : null,
              )
              : null,
        })}
      allowNull
      parse={(value) => (isNotEmptyArray(value) ? value : null)}
    >
      {({ input }) => {
        const inputValue = input.value;

        const myArrayFiltered = isNotEmptyArray(items) ? items.filter((el) => {
          return isNotEmptyArray(inputValue) && inputValue.some((f) => {
            return f === el.DisplayName || f === el.Name;
          });
        }) : [];

        const selectedItems = isNotEmptyArray(myArrayFiltered) ? myArrayFiltered.map((e) => {
          return e.DisplayName;
        }) : [];

        const isSelectedAllValues = (isNotEmptyArray(inputValue) && isNotEmptyArray(items)) ? inputValue.length === items.length : false;

        return (
          <div
            className={
              isNotNullAndUndefined(props.formGroupClassName)
                ? props.formGroupClassName + " form-group"
                : "form-group"
            }
          >
            <div className={rowClassName} id={"rafdiv" + field.toString()}>
              {showLabel && showLabel === true && (
                <RAFFieldLabel
                  field={field}
                  label={label}
                  required={required}
                  labelClassName={labelClassName}
                  description={description}
                  descriptionAsLabel={descriptionAsLabel}
                  rightSection={
                    isNotNullAndUndefined(props.labelRightSection) ?
                      props.labelRightSection
                      :
                      <div className="ms-auto d-flex justify-content-end align-items-center">
                        <CheckBoxComponent
                          name={`${field.toString()}_selectAll`}
                          value={'Select All'}
                          className="custom-control-input"
                          change={(e) => onChangeSelectAllCheckBox(e.checked)}
                          checked={isSelectedAllValues}
                          label={"Select All"}
                        />
                      </div>
                  }
                ></RAFFieldLabel>
              )}
              <div className={inputFieldClassName}>
                <div>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedItems}
                    onChange={(e) => handleChange(e, input)}
                    input={<OutlinedInput />}
                    renderValue={(selected) => selected.join(', ')}
                    hiddenLabel={true}
                    className="raf_mui_input"
                    {...isNotNullAndUndefined(props.placeholder) ?
                      { placeholder: props.placeholder }
                      :
                      {}
                    }
                    disabled={disabled}
                  >
                    {items.map((name) => (
                      <MenuItem key={name.Name} value={name.DisplayName}>
                        <ListItemText primary={name.DisplayName} />
                        <Checkbox checked={isNotNullAndUndefined(inputValue) && inputValue.indexOf(name.Name) > -1} />
                      </MenuItem>
                    ))}
                  </Select>
                  {props.hideRequiredMessage !== true ? (
                    <RAFFieldError name={field.toString()} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Field>
  );
}

export default RAFMultiSelectDropdownMUI;
